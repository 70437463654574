import { defineStore } from 'pinia';
import { DAL } from 'firework-bll';
import { handleRequest } from '@/utils/request';
import { removeToken, setToken, getToken } from '@/utils/auth';

const UserState = defineStore('user', {
  state: () => {
    return {
      userInfo: {
        UserName: '', //用户名
        LoginType: 0, //账户类型: 0 - 邮箱 1 - google 2 - Facebook
        UserWithThird: 0, //第三方账号ID
        BindAddrType: 0, //绑定类型: 0 -未绑定 1 - metamask 2 - 托管钱包
        WalletAddress: '', //绑定钱包地址
        UserWithThirdName: '', //第三方账号昵称
        isWeb3: undefined, // 是否是web3用户: 0 - 否 1 - 是
      },
      token: getToken(),
    };
  },

  actions: {
    loginThird(token: string) {
      return new Promise((resolve, reject) => {
        this.clearAccount();
        this.token = token;
        setToken(token);
        resolve(token);
      });
    },
    login(username: string, password: string) {
      return new Promise((resolve, reject) => {
        DAL.user
          .login(username, password)
          .then(handleRequest)
          .then((res: any) => {
            const { token } = res;
            this.clearAccount();
            this.token = token;
            setToken(token);
            resolve(res);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },
    logout() {
      return new Promise((resolve, reject) => {
        DAL.user
          .logout()
          .then(handleRequest)
          .then((res: any) => {
            this.clearAccount();
            resolve(res);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        DAL.user
          .getUserInfo()
          .then(handleRequest)
          .then((res: any) => {
            console.log('getUserInfo', res);
            this.userInfo = res;
            resolve(res);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },
    clearAccount() {
      this.token = '';
      removeToken();
    },
  },
});

export default UserState;
