import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import 'virtual:windi.css';
import router from './router';
import { env } from '@firework/env';
import App from './App.vue';
// import '@/store/global';
import './styles/index.css';
// import 'normalize.css';

// vant国际化 引入英文语言包
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
Locale.use('en-US', enUS);

const app = createApp(App);
const pinia = createPinia();
if (process.env.FIREWORK_ENV === 'production') {
  Sentry.init({
    app,
    dsn: env.SENTRY_DSN_SSO,
    release: '1.0',
    environment: 'production',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        // tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

// 引入vue3-google-login
import vue3GoogleLogin from 'vue3-google-login';
const clientId = '348384692770-5c7l8uvmq0s747af4g7l1unhe03bvuhp.apps.googleusercontent.com';
app.use(vue3GoogleLogin, {
  clientId,
  popupType: 'TOKEN',
  //   idConfiguration: {
  //     hosted_domain: window.location.origin,
  //   },
});

app.use(router);
app.use(pinia);
router.isReady().then(() => app.mount('#app'));
