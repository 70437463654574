import Cookies from 'cookies-ts';
import { env } from '@firework/env';

const cookies = new Cookies();

const TokenKey = env.AUTHTOKEN_NAME;
const domain = '.firework.games';

export function getToken() {
  return cookies.get(TokenKey);
}

export function setToken(token: string) {
  return cookies.set(TokenKey, token, { domain });
}

export function removeToken() {
  return cookies.remove(TokenKey, { domain });
}
