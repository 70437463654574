import { createRouter, createWebHistory } from 'vue-router';
import generatedRoutes from '~pages';
import useUserStore from '@/store/user';

const customRoutes = [{ path: '/', redirect: '/login', name: 'Spark Era Login' }];

const routes = [...generatedRoutes, ...customRoutes];

console.log('routes', routes);
const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

router.beforeEach(async to => {
  // console.log('afterEach');
  document.title = to.name as string;
  // 页面跳转后滚动至顶部
  window.scrollTo(0, 0);

  const userStore = useUserStore();
  if (userStore.token) {
    // 判断当前用户是否已获取用户信息
    if (!userStore.userInfo.UserName) {
      try {
        await userStore.getUserInfo();
      } catch (error) {
        console.log('beforeEach getUserInfo error', error);
        // return true;
      }
    }
  }
});
export default router;
