import { Dialog } from 'vant';
import 'vant/es/dialog/style/less';
import useUserStore from '@/store/user';
import { useRouter, useRoute } from 'vue-router';

// response 统一处理封装
const router = useRouter();
const route = useRoute();

// 是否显示重新登录
export let isRelogin = { show: false };

export function handleRequest(response: any): Promise<any> {
  console.log('handleRequest', response);
  // 未设置状态码则默认成功状态
  const code = response.code || 200;
  // 获取错误信息
  // const msg = response.codeError;
  // 二进制数据则直接返回
  // if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
  //   return res.data;
  // }
  // if (code === 200) {
  //   return Promise.resolve(response);
  // } else {
  //   response.codeError = msg;
  //   return Promise.reject(response);
  // }
  if (code === 401) {
    // 直接登出
    const userStore = useUserStore();
    userStore.logout().then(res => {
      router.replace({ name: 'login', query: { ...route.query } });
      // location.reload();
    });
    // if (!isRelogin.show) {
    //   isRelogin.show = true;
    //   Dialog.confirm({
    //     title: 'Tips',
    //     message: 'Your login status has expired, you can stay on this page or log in again.',
    //     confirmButtonText: 'Re-login',
    //     theme: 'round-button',
    //   })
    //     .then(() => {
    //       isRelogin.show = false;
    //       const userStore = useUserStore();
    //       userStore.logout().then(res => {
    //         router.replace({ name: 'login', query: { ...route.query } });
    //         // location.reload();
    //       });
    //     })
    //     .catch(() => {
    //       isRelogin.show = false;
    //     });
    // }
    // return Promise.reject('无效的会话，或者会话已过期，请重新登录。');
    return Promise.reject('Invalid session, or session has expired, please log in again.');
    // } else if (code === 500) {
    //   // ElMessage({
    //   //   message: msg,
    //   //   type: 'error',
    //   // });
    //   return Promise.reject(new Error(msg));
  } else if (response.code !== 200 || response.success === false) {
    // ElNotification.error({
    //   title: msg,
    // });
    const message = response.msg || response.codeError;
    Dialog.alert({
      title: 'Failed',
      message,
      theme: 'round-button',
    });
    return Promise.reject(message);
    // return Promise.reject(response.msg || response.codeError);
  } else {
    return Promise.resolve(response.data);
  }
}
